import { ThemeType, UpdateProductOnboardingDocument } from '@cycle-app/graphql-codegen';
import { Input, Spinner } from '@cycle-app/ui';
import { NotebookIcon } from '@cycle-app/ui/icons';
import { isUrl, getDomain } from '@cycle-app/utilities';
import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useFetchCompanyLogo, useSafeMutation } from 'src/hooks';
import { useEnhancedForm } from 'src/hooks/form/useEnhancedForm';
import { setOnboarding, useGetOnboarding } from 'src/reactives/lightOnboarding.reactive';
import { LightOnboardingScreen } from 'src/types/onboarding.types';

// Replace with the right images, the ones in figma are not suited to add dynamic values + no dark theme.
import asideDarkIllustration from './aside.dark.svg';
import asideLightIllustration from './aside.light.svg';
import { OnboardingLayout } from '../OnboardingLayout/OnboardingLayout';
import { Footer, Form, FormGrid, NextButton, BackButton } from '../OnboardingLayout/OnboardingLayout.styles';


interface FormData {
  url: string;
}

export const OnboardingStepChangelog = ({
  shouldBookCall, productId, productSlug,
}: {
  productId: string;
  shouldBookCall: boolean;
  productSlug: string;
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useEnhancedForm<FormData>({
    defaultValues: {
      url: '',
    },
  });
  const { theme } = useGetOnboarding();
  const [isFetchingFavicon, setIsFetchingFavicon] = useState(false);
  const [favicon, setFavicon] = useState('');
  const [updateMutationUrl, updateMutationUrlState] = useSafeMutation(UpdateProductOnboardingDocument);

  const { fetch: fetchLogo } = useFetchCompanyLogo();

  const fetchLogoDebounced = useDebouncedCallback(async (domain: string) => {
    setIsFetchingFavicon(true);
    const response = await fetchLogo({ domain });
    const logo = response?.companyLogo;
    if (logo) setFavicon(logo);
    setIsFetchingFavicon(false);
  }, 200);

  const onSubmit = async (data: FormData) => {
    if (isUrl(data.url)) {
      await updateMutationUrl({
        variables: {
          productId,
          changelogUrl: data.url,
        },
      });
    }
    setOnboarding({ screen: shouldBookCall ? LightOnboardingScreen.BookCall : LightOnboardingScreen.Done });
  };

  const changelogUrl = watch('url');
  return (
    <OnboardingLayout
      progressAbsolute
      headline={(
        <>
          <NotebookIcon className="hidden tablet:block" />
          Communicate your release notes with customers
        </>
      )}
      title="Do you currently have a changelog?"
      aside={(
        <div className="absolute left-0 top-0">
          <img src={theme === ThemeType.Sunrise ? asideLightIllustration : asideDarkIllustration} className="max-w-[unset]" />
          <div
            className="absolute size-5 overflow-clip rounded" style={{
              top: 197,
              left: 226,
            }}
          >
            {favicon ? <img src={favicon} className="size-full object-cover" /> : <div className="size-full bg-grey-200 dark:bg-grey-850" />}
          </div>
          <div
            className="absolute size-5 overflow-clip rounded" style={{
              top: 378,
              left: 156,
            }}
          >
            {favicon ? <img src={favicon} className="size-full object-cover" /> : <div className="size-full bg-grey-200 dark:bg-grey-850" />}
          </div>

          {changelogUrl && (
            <div
              className="absolute max-w-[365px] truncate whitespace-nowrap text-secondary" style={{
                top: 233,
                left: 214,
              }}
            >
              {changelogUrl}
            </div>
          )}
          {productSlug && (
            <div
              className="absolute max-w-[365px] truncate whitespace-nowrap text-secondary" style={{
                top: 413,
                left: 141,
              }}
            >
              https://changelog.cycle.app/{productSlug}/
            </div>
          )}
        </div>
      )}
      main={(
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGrid>
            <div>
              <Input
                autoFocus
                label="Your current changelog URL"
                placeholder="Changelog URL"
                helper="We will fetch the content for you and help you with the migration."
                error={errors.url?.message}
                {...register('url', {
                  validate: (value) => {
                    if (!!value.trim()) {
                      return isUrl(value) ? undefined : 'Invalid url';
                    }
                    return undefined;
                  },
                  onChange: (e) => {
                    const domain = getDomain(e.target.value.trim());
                    if (domain) {
                      // eslint-disable-next-line @typescript-eslint/no-floating-promises
                      fetchLogoDebounced(domain);
                    }
                  },
                })}
              />
            </div>
            <div className="h-12">
              {!!isUrl(changelogUrl) && (
                <div className="flex h-full items-center gap-3 rounded-lg border border-primary px-3">
                  {isFetchingFavicon && <Spinner className="mx-auto" />}
                  {!isFetchingFavicon && favicon && <img src={favicon} className="size-4 shrink-0 object-cover" loading="lazy" />}
                  {!isFetchingFavicon && favicon && <div className="grow truncate text-body font-medium">{changelogUrl}</div>}
                </div>
              )}
            </div>
          </FormGrid>
          <Footer>
            <BackButton
              size="M"
              onClick={() => setOnboarding({ screen: LightOnboardingScreen.FlowSelection })}
            >
              Back
            </BackButton>
            <NextButton
              size="M"
              type="submit"
              isLoading={updateMutationUrlState.loading}
            >
              Next
            </NextButton>
          </Footer>
        </Form>
      )}
    />
  );
};
